import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"

export default function Footer() {
  return (
    <div className="row">
      <footer className="col-md-12 page-footer">
        <div>
          <Row>
            <Col md={4} className="footer__header">
              <h4>ITALIAN ART TRUST</h4>
              <a href="/sostienici">
                <button className="IAT__button__footer" href="/sostienici">
                  SOSTIENICI
                </button>{" "}
              </a>
            </Col>

            <Col md={8} className="footer__header">
              <p> </p>© 2020 Copyright:{" "}
              <Link to="/" className="footer__link">
                Italian Art Trust O.N.L.U.S.
              </Link>{" "}
              C.F. 97873930156<br></br>
              <a className="footer__link" href="/privacy">
                Privacy Policy
              </a>{" "}
              <a className="footer__link" href="/cookie">
                Cookie Policy
              </a>{" "}
            </Col>
          </Row>
        </div>
      </footer>
    </div>
  )
}
